<template>
  <div class="full-page content-center">
    <v-container>
      <div class="login-box">
        <div class="headline-container">
          <h1>
            เข้าสู่ระบบ
          </h1>
          <p class="mb-0">
            กรุณาเข้าสู่ระบบด้วยบัญชี ITEasy เพื่อเข้าใช้งาน
          </p>
        </div>

        <div class="login-form">
          <div class="w-full">
            <v-text-field
              v-model="form.username"
              :error="$v.form.username.$error"
              :error-messages="
                $v.form.username.$error ? 'กรุณากรอกรหัสพนักงาน' : ''
              "
              :disabled="isLoading"
              aria-autocomplete="off"
              autocomplete="off"
              label="รหัสพนักงาน"
              placeholder=" "
              outlined
              dense
              class="w-full"
              oninput="this.value=this.value.replace(/[^\d]+/g, '')"
            />
          </div>

          <div class="w-full">
            <v-text-field
              v-model="form.password"
              :error="$v.form.password.$error"
              :error-messages="
                $v.form.password.$error ? 'กรุณากรอกรหัสผ่าน' : ''
              "
              :disabled="isLoading"
              aria-autocomplete="off"
              autocomplete="off"
              label="รหัสผ่าน"
              placeholder=" "
              outlined
              dense
              type="password"
              class="w-full"
            />
          </div>
        </div>
        <v-btn
          depressed
          color="#459d43"
          :loading="isLoading"
          @click="submit"
        >
          <span class="text-white">
            เข้าสู่ระบบ
          </span>
        </v-btn>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import colorBnn from '@/config/color'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'Login',

  mixins: [validationMixin],

  data() {
    return {
      colorBnn,
      form: {
        username: null,
        password: null,
      },
    }
  },

  validations: {
    form: {
      username: {
        required: required,
      },
      password: {
        required: required,
      },
    },
  },

  computed: {
    ...mapGetters({
      isLoggedIn: 'auth/isLoggedIn',
      isLoading: 'auth/isLoading' || 'stock/isLoading',
    }),
  },
  methods: {
    async submit() {
      this.$v.$touch()

      if (this.$v.$invalid) {
        return
      }

      const payload = {
        emp_code: this.form.username,
        pass: this.form.password,
        device_uuid: 'web',
        platform: 'web',
      }

      await this.$store.dispatch('auth/login', payload)
    },
  },
  mounted() {
    // If the user is already logged in, redirect to localStorage.getItem('redirectPage') or '/'
    if (this.isLoggedIn) {
      this.$router.push(localStorage.getItem('redirectPage') || '/')
    }
  },
}
</script>

<style lang="stylus" scoped>

.login-box{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 24px;
  text-align: left
  max-width: 390px
  margin 0 auto

  .headline-container{
	width: 100%
	text-align: center
  }

  .login-form {
	width: 100%
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 8px;
	margin: 24px 0 8px
  }

  h1 {
	font-size: 24px;
  }

  p {
	font-size: 16px;
	color: #666;
  }
}

.text-white {
	color: #fff;
}
</style>
